import React from 'react';



var alphabet=[["a","amonia"],["b","bromine"],["c","cathode"],
					["d","differential"],["e","echo"],["f","formula"],
					["g","generate"],["h","hydrogen"],["i","inference"],
					["j","jitter"],["k","kelvin"],["l","lithium"],
					["m","methane"],["n","nitrate"],["o","oxygen"],
					["p","propane"],["q","query"],["r","rhombus"],
					["s","saturated"],["t","thylonol"],["u","urban"],
					["v","venerable"],["w","wet"],["x","xeno"],
					["y","yIntercept"],["z","zyph"],[" ",""]]
var alphabet2=[["a","4Act.U.ate"],["b","B3RR.ies"],["c","Cc4p.ital"],
					["d","d3.5tr0yer"],["e","3co.SyStem"],["f","Ffr33.dom"],
					["g","G3N.erate"],["h","hIiST0Ry"],["i","1nter.d1menSional"],
					["j","Jjump.3rs"],["k","K1ngs"],["l","L4bel"],
					["m","Mm3rcan.tile"],["n","nitrate"],["o","oxygen"],
					["p","PreHenSile"],["q","Qquantify"],["r","React.ive"],
					["s","5Ssig.nal"],["t","TtRrIi.AnGgLlEe3"],["u","UuNnIi1.LATERAL"],
					["v","venerable"],["w","wet"],["x","xeno"],
					["y","Yuuuuge"],["z","Zing"],[" ","space"]]


function selectElementText(el, win) {
    win = win || window;
    var doc = win.document, sel, range;
    if (win.getSelection && doc.createRange) {
        sel = win.getSelection();
        range = doc.createRange();
        range.selectNodeContents(el);
        sel.removeAllRanges();
        sel.addRange(range);
    } else if (doc.body.createTextRange) {
        range = doc.body.createTextRange();
        range.moveToElementText(el);
        range.select();
    }
}



function gen1(props) {
  var input = document.getElementById("starterString").value
  var inString= input
  var genString = ""
  for (var i = 0; i < inString.length; i++) {
    var matchFound = false
    for (var x = 0; x < alphabet.length; x++) {
      if(inString[i] == alphabet[x][0]){
        genString += alphabet[x][1].toString()+" "
        matchFound = true
      }
    }
    if(matchFound==false){
        genString += inString[i]
        matchFound=false
    }
  }
  var resultDiv = document.getElementById("result")
  resultDiv.innerHTML=genString;
  selectElementText(document.getElementById("result"));
}
function gen2(props) {
  var input = document.getElementById("starterString").value
  var inString= input
  var genString = ""

	for (var i = 0; i < inString.length; i++) {
		var matchFound = false
		for (var x = 0; x < alphabet.length; x++) {
			if(inString[i] == alphabet[x][0]){
				genString += alphabet[x][1].toString()+"space"
				matchFound = true
			}
		}
		if(matchFound==false){
				genString += inString[i]
				matchFound=false
		}


	}
  var resultDiv = document.getElementById("result")
  resultDiv.innerHTML=genString;
  selectElementText(document.getElementById("result"));
}
function gen3(props) {
  var input = document.getElementById("starterString").value
  var inString= input
  var genString = ""
  for (var i = 0; i < inString.length; i++) {
    var matchFound = false
    for (var x = 0; x < alphabet.length; x++) {
      if(inString[i] == alphabet[x][0]){
        genString += alphabet2[x][1].toString()+"yek"
        matchFound = true
      }
    }
    if(matchFound==false){
        genString += inString[i]
        matchFound=false
    }


  }
  var resultDiv = document.getElementById("result")
  resultDiv.innerHTML=genString;
  selectElementText(document.getElementById("result"));
}

const genField={
  width:"20em",
}
const fieldStyle=
{
  width:"20em",
  padding:"1em",
  backgroundColor:"black",
  color:"white",
  fontWeight:"bold",
  backgroundImage: "linear-gradient(to right, rgba(0, 170, 255, 0.2), rgba(0, 170, 255, 0))"
}
const btnStyle=
{
  width:"31%",
  padding:"1em",
  backgroundColor:"black",
  color:"white",
}
const resultStyle=
{
  margin:"3em",
  padding:"1em"
}




function Welcome(){
  return(
    <div>
      <div className="App-Background"></div>
      <div className="content">
        <div className="ph-no-capture">
          <div className="vertical">
            <div style={genField}>
            <form>
              <input style={fieldStyle}id="starterString"/>
            </form>
            <button  style={btnStyle} id="submit"onClick={gen1} >GEN1</button>
            <button  style={btnStyle} id="submit"onClick={gen2} >GEN2</button>
            <button  style={btnStyle} id="submit"onClick={gen3} >GEN3</button>
            </div>
            <div style={resultStyle} id="result">Result goes here</div>

          </div>
        </div>
      </div>
    </div>
  )
}
export default Welcome
