import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';


const Accordion = ({ markdownArray }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div>
      {markdownArray.map((markdown, index) => {
        const lines = markdown.split('\n');
        const title = lines[0].trim();
        //const mdcontent =  markdown.split('\n').slice(1); ???
        const mdcontent = lines.slice(1).join('\n').trim();

        return (
          <div key={index} className="duration-500">
            <button
              className="text-info-content bg-info opacity-80 hover:opacity-100 primary px-4 py-2 rounded-md w-full text-left duration-500"
              onClick={() => handleClick(index)}
            >
              <h2 className="text-lg font-medium">{title}</h2>
            </button>
            {activeIndex === index && (
              <div className=" bg-info text-info-content opacity-60 p-4 ">
                <ReactMarkdown children={mdcontent} remarkPlugins={[remarkGfm]}></ReactMarkdown>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;