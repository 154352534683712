import React from "react";
import Clock from "../components/Clock"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
import Accordion from '../components/Accordion';
import ReactPlayer from 'react-player'
import CarouselCard from "../components/CarouselCard";


const hypergraph1md = `
# HYPERGRAPH 1

## What is it?
This is a collection of 400 vector heightmaps compressed into a Substance Designer Archive (SBSAR).
There's not many other ways that this much information could fit in a mobile texture library like this one.

## How can I use it?
As this is a substance designer archive you will either need a working copy of Substance Designer/Painter/Stager
or the *free standalone Substance Player*.

There are also plugins that will allow you to painlessly use these textures in several DCC suites.
- Houdini Labs Substance Plugin.
- Unreal Engine Substance Tools on the Marketplace.
- Blender Substance add-on.

It's easier than working with plain textures since each texture will be mapped to your shader graph with the correct
color space automatically. 


## Intended Use:

This Is intended for video games, virtual production, or film projects. 

## Restrictions:
Credit is appreciated.
Machine Learning, Software as a Service (SaaS) rights not included.
For other concerns please contact sales.






## Changelog:
4/23/2023 - Released v4, with linear 1-400 pattern id input for simplicity. FXAA anti-aliasing on height-map output.


## DemoScene / Preview
- video
- glb (graphics library binary)
- sample



`
const accordmarkdown = [
  `Pattern:
- Generate a stored pattern by selecting the pattern id (1-400).
`, `Dirt (two layers):
- level
- color
- roughness
- metalness
`,
  `Color & Roughness:
- 5 histogram regions for setting color and roughness
- 3 histogram regions for setting metalness
`,
  `Outputs:
- base color
- roughness
- metalness
- normal
- height
- emission
`
];
const videoSources = [
  '/vol12a_12.mp4',
  '/vol12a_59.mp4',
  '/vol12a_83.mp4',
  '/vol12a_89.mp4',
  '/vol12a_103.mp4',
];

export default function Hypergraph1() {
  return (
    <div>
      {/* <Clock /> */}
      <div>
        <div class="bigvid" >
          <video class="bigvid opacity-50" autoPlay={true} playsInline={true} muted={true} loop={true} src="/hgraph1_teaser_4.mp4" >
          </video>
          <div class="text-overlay">
            <h1 class="text-center lg:text-9xl">Hypergraph1</h1>
            <p class="pt-5 ">400 Materials in a 20MB package
              <div className="badge badge-outline badge-neutral font-bold mx-1">Q1-2023</div>
              <div className="badge badge-outline badge-neutral font-bold mx-1">Spotlight</div>
              <div className="badge badge-outline badge-neutral font-bold mx-1">Adobe Substance Designer</div>
              <div className="badge badge-outline badge-neutral font-bold mx-1">SBSAR</div>
            </p>
            <a href="#description"><button class="btn btn-primary m-2 text-3xl"> ▼ Info</button></a>
          </div>
        </div>
        <div class="block-inline flex col-2">
          <div id="description" class="mx-5 w-1/2">
            <ReactMarkdown children={hypergraph1md} remarkPlugins={[remarkGfm]} />
          </div>
          <div>
            <ReactMarkdown children={"## Controls/Parameters:"} remarkPlugins={[remarkGfm]} />
            <Accordion class="w-1/2" markdownArray={accordmarkdown} />

            <div id="options">
              <ReactMarkdown children={"## Purchase Options:"} remarkPlugins={[remarkGfm]} />
              <a class="btn text-2xl btn-primary" href="https://rh2.gumroad.com/l/hypergraph1"> $40 — Buy on Gumroad</a>
            </div>
          </div>



          <div>.</div>
        </div>
      </div>
    </div>
  );
}