import React from "react";
import Clock from "../components/Clock"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
import Accordion from '../components/Accordion';
import ReactPlayer from 'react-player'
import CarouselCard from "../components/CarouselCard";


const FreeKitmd = `
# SWIZZLE — Freekit

## Just a few things:
- Wetland_lakeshore
- sidewalk_basic
- freshly painted cave
- rock
- rock with dirt
- tile
- geode
- strewn rubble
- Several types of concrete

## Restrictions:

- Source Redistribution: Attribution
- Derivatives: CC0

## DemoScene / Preview
- video
- glb
- sample
`
const accordmarkdown = [
  `Pattern:
- Generate a stored pattern by selecting the pattern id (1-400).
`, `Dirt (two layers):
- level
- color
- roughness
- metalness
`,
  `Color & Roughness:
- 5 histogram regions for setting color and roughness
- 3 histogram regions for setting metalness
`,
  `Outputs:
- base color
- roughness
- metalness
- normal
- height
- emission
`
];
const videoSources = [
  '/vol12a_12.mp4',
  '/vol12a_59.mp4',
  '/vol12a_83.mp4',
  '/vol12a_89.mp4',
  '/vol12a_103.mp4',
];

export default function Freekit() {
  return (
    <div>
      {/* <Clock /> */}
      <div>
        <div class="bigvid" >
          <video class="bigvid opacity-20" autoPlay={true} playsInline={true} muted={true} loop={true} src="/Swizzle-Light-Landing-Bg3-1.mp4" >
          </video>
          <div class="text-overlay">
            <h1 class="text-center lg:text-9xl">FreeKit</h1>
            <p class="pt-5 ">Introductions should be easy ♥️
              <div className="badge badge-outline badge-neutral font-bold mx-1">Spotlight</div>
              <div className="badge badge-outline badge-neutral font-bold mx-1">SBSAR</div>
            </p>
            <a href="#description"><button class="btn btn-primary m-2 text-3xl"> ▼ Info</button></a>
          </div>
        </div>
        <div class="block-inline flex col-2">
          <div id="description" class="mx-5 w-1/2">
            <ReactMarkdown children={FreeKitmd} remarkPlugins={[remarkGfm]} />
          </div>
          <div>
            <ReactMarkdown children={"## Purchase Options:"} remarkPlugins={[remarkGfm]} />
            <div>
              <a class="btn text-2xl btn-primary" href="/Swizzle_freekit1.sbsar"> $0— Direct Download</a> </div>
          </div>

          

          <div>.</div>
        </div>
      </div>
    </div>
  );
}