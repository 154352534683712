import React from "react";
import Clock from "../components/Clock"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
import Accordion from '../components/Accordion';
import ReactPlayer from 'react-player'
import CarouselCard from "../components/CarouselCard";


const Hypergraph2md = `
# HYPERGRAPH 2

## A *Material Library* From The Future
This is a collection of 100 vector heightmaps compressed into a Substance Designer Archive (SBSAR).
There's not many other ways all of this information could fit into a mobile texture library like this one.

## How can I use it?
As this is a substance designer archive you will either need a working copy of Substance Designer/Painter/Stager
or the *free standalone Substance Player*.

There are also plugins that will allow you to painlessly use these textures in several DCC suites.
- Houdini Labs Substance Plugin.
- Unreal Engine Substance Tools on the Marketplace.
- Blender Substance add-on.

It's easier than working with plain textures since each texture will be mapped to your shader graph with the correct
color space automatically. No more manual hookups! This is how it should be: Textures should be attached to materials automatically!

## Intended Use:

This Is intended for video games, virtual production, or film projects. 

## Restrictions:
Credit is appreciated.
Machine Learning, Software as a Service (SaaS) rights not included.
For other concerns please contact sales.

## DemoScene / Preview
- video
- glb
- sample
`
const accordmarkdown = [
`Pattern:
Integer 1-100, to select the active heightmap`,
`Primary Panel:
- Base Color
- Roughness
- Metallic properties of 4 histogram regions.`, 
`Screen (unused)
- future date ~`,
`Heightmap Effects:
- Contrast Mod
- Luminosity Mod
- Flood Color Gradient (1-20)- 
- Flood Hue
- Flood Saturation
- Panel Random Flood Opacity
- Random Normal
- Random Panel Gradient Position
- Random Panel Gradient Range(Hint: Random Flood Fill can be adjusted via random seed)`,
`Decal
  
- Silkscreen Color 1,2,3
- Silkscreen Roughness 1,2,3`,
`Dirt
  
- Color
- Roughness
- Metalness
- Level
- Contrast
- Amount
- Masking
- Scale
- Smudge Amount
- Smudge Angle
- Dirt Reduction Level (Edges)`,
`Outputs:
- base color
- roughness
- metalness
- normal
- height`
];
const videoSources = [
  '/vol12a_12.mp4',
  '/vol12a_59.mp4',
  '/vol12a_83.mp4',
  '/vol12a_89.mp4',
  '/vol12a_103.mp4',
];

export default function Hypergraph2() {
  return (
    <div>
      {/* <Clock /> */}
      <div>
        <div class="bigvid" >
          <video class="bigvid opacity-50" autoPlay={true} playsInline={true} muted={true} loop={true} src="/hgraph2_teaser.mp4" >
          </video>
          <div class="text-overlay">
            <h1 class="text-center lg:text-9xl">Hypergraph2</h1>
            <p class="pt-5 ">100 Materials
              <div className="badge badge-outline badge-neutral font-bold mx-1">Q2-2023</div>
              <div className="badge badge-outline badge-neutral font-bold mx-1">Spotlight</div>
              <div className="badge badge-outline badge-neutral font-bold mx-1">Adobe Substance Designer</div>
              <div className="badge badge-outline badge-neutral font-bold mx-1">SBSAR</div>
            </p>
            <a href="#description"><button class="btn btn-primary m-2 text-3xl"> ▼ Info</button></a>
          </div>
        </div>
        <div class="block-inline flex col-2">
          <div id="description" class="mx-5 w-1/2">
            <ReactMarkdown children={Hypergraph2md} remarkPlugins={[remarkGfm]} />
          </div>
          <div>
            <ReactMarkdown children={"## Controls/Parameters:"} remarkPlugins={[remarkGfm]} />
            <Accordion class="w-1/2" markdownArray={accordmarkdown} />
            <ReactMarkdown children={"## Purchase Options:"} remarkPlugins={[remarkGfm]} />
            <div id="options">
              <a class="btn text-2xl btn-primary" href="https://rh2.gumroad.com/l/Hypergraph2"> $25 — Buy on Gumroad</a> </div>
          </div>

          

          <div>.</div>
        </div>
      </div>
    </div>
  );
}