import React from "react";
import Clock from "../components/Clock"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
import Accordion from '../components/Accordion';
import ReactPlayer from 'react-player'
import CarouselCard from "../components/CarouselCard";


const PolyFramemd = `
# POLYFRAME

## What is it?
This is a collection of 75 real cloth sims. Height and Normal information was baked directly from vellum simulations in Houdini. The Frame
heightmaps were drawn with vector art. Since the raster graphics are sampled at 2048^2 resolution; that is a natural resolution for the output.

## How can I use it?
As this is a substance designer archive you will either need a working copy of Substance Designer/Painter/Stager
or the *free standalone Substance Player*.

There are also plugins that will allow you to painlessly use these textures in several DCC suites.
- Houdini Labs Substance Plugin.
- Unreal Engine Substance Tools on the Marketplace.
- Blender Substance add-on.

It's easier than working with plain textures since each texture will be mapped to your shader graph with the correct
color space automatically. No more manual hookups! This is how it should be: Textures should be attached to materials automatically!

## Intended Use:

This Is intended for video games, virtual production, or film projects. 

## Restrictions:
Credit is appreciated.
Machine Learning, Software as a Service (SaaS) rights not included.
For other concerns please contact sales.





## Changelog:
- No changes to report.


## DemoScene / Preview
- video
- glb (graphics library binary)
- sample
`
const accordmarkdown = [
`Pattern:
- Generate a stored pattern by selecting the pattern id (1-75).`,

`Basic:

- Frame Color 
- Pad Color
- Frame Roughness
- Pad Roughness
- Frame Metalness
- Pad Metalness`,

`Dirt:
- Dirt Level
- Dirt Contrast
- Grunge Amount
- Edges Masking
- Grunge Scale
- Dirt Scratches
- Dirt Color
- Dirt Roughness
- Dirt Metallic`,

`Normal:
- Normal Format (OpenGL/DirectX)`
];
const videoSources = [
  '/vol12a_12.mp4',
  '/vol12a_59.mp4',
  '/vol12a_83.mp4',
  '/vol12a_89.mp4',
  '/vol12a_103.mp4',
];

export default function PolyFrame() {
  return (
    <div>
      {/* <Clock /> */}
      <div>
        <div class="bigvid" >
          <video class="bigvid opacity-50" autoPlay={true} playsInline={true} muted={true} loop={true} src="/polyframe_teaser.mp4" >
          </video>
          <div class="text-overlay">
            <h1 class="text-center lg:text-9xl">PolyFrame</h1>
            <p class="pt-5 ">Framed Fabric Panels Made With Real Simulations
              <div className="badge badge-outline badge-neutral font-bold mx-1">Adobe Substance Designer</div>
              <div className="badge badge-outline badge-neutral font-bold mx-1">SBSAR</div>
            </p>
            <a href="#description"><button class="btn btn-primary m-2 text-3xl"> ▼ Info</button></a>
          </div>
        </div>
        <div class="block-inline flex col-2">
          <div id="description" class="mx-5 w-1/2">
            <ReactMarkdown children={PolyFramemd} remarkPlugins={[remarkGfm]} />
          </div>
          <div>
            <ReactMarkdown children={"## Controls/Parameters:"} remarkPlugins={[remarkGfm]} />
            <Accordion class="w-1/2" markdownArray={accordmarkdown} />
            <ReactMarkdown children={"## Purchase Options:"} remarkPlugins={[remarkGfm]} />
            <div id="options">
              <a class="btn text-2xl btn-primary" href="https://rh2.gumroad.com/l/PolyFrame"> $25 — Buy on Gumroad</a> </div>
          </div>

          

          <div>.</div>
        </div>
      </div>
    </div>
  );
}