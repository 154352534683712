import React, { useState } from 'react';

const Navbar = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    const handleThemeToggle = () => {
        setIsDarkMode(!isDarkMode);
    };

    const themeClass = isDarkMode ? 'dark' : 'light';
    const othernavitems = 
    `
    <div>
    <a href="/hypergraph1" className="btn btn-ghost normal-case text-xl">Hypergraph1</a>
    <a href="/hypergraph2" className="btn btn-ghost normal-case text-xl">Hypergraph2</a>
    <a href="/polyframe" className="btn btn-ghost normal-case text-xl">PolyFrame</a>
    <a href="/freekit" className="btn btn-ghost normal-case text-xl">FreeKit</a>
</div>
    <label className="flex cursor-pointer gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="5"/><path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4"/></svg>
        <input type="checkbox" value="synthwave" className="toggle theme-controller"/>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>
        </label>
    
    `

    return (
        <div className="navbar">
            <div className="flex-1">
                <a href="/" className="btn btn-ghost upper-case text-2xl">Swizzle.fun</a>
            </div>
            <label className="flex cursor-pointer gap-2 text-2xl">© 2024</label>
        </div>
    );
};

export default Navbar;
